
.App {
	font-family: sans-serif;
	/* text-align: center; */
}

#error .MuiSnackbarContent-root{
	background-color: rgb(210, 82, 82);
}
#success .MuiSnackbarContent-root{
	background-color: rgb(46,204,113);
}
#portfolioSelect {
	background-color: rgba(0,115,237,0.1) !important;
}
a:hover{
	text-decoration: none !important;
}

#promptModal .MuiPaper-root{
	background-color: transparent !important;

}
#faqAccordion ul{
	padding-left: 18px;
}

#levelSelectStyles .MuiFormLabel-root{
	color: red !important;
}